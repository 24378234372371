import consumer from "./consumer"

consumer.subscriptions.create("TeleinfomsgChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected !");
    
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    console.log("Diconnected !");
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    console.log("Received =====")   ;
    console.log(data)   ;

    let papp_element = document.getElementById('id_papp'); 
    papp_element.innerHTML = data.papp + " W";
    papp_element.classList.remove("bg-success", "bg-warning", "bg-danger");
    papp_element.classList.add(Number(data.papp)< 2000 ? "bg-success" : (Number(data.papp) < 6000 ? "bg-warning" : "bg-danger") );

    let iinst_element = document.getElementById('id_iinst'); 
    iinst_element.innerHTML = data.iinst + " A";
    iinst_element.classList.remove("bg-success", "bg-warning", "bg-danger");
    iinst_element.classList.add(Number(data.iinst)< 10 ? "bg-success" : (Number(data.iinst) < 30 ? "bg-warning" : "bg-danger") );

    let ptec_element = document.getElementById('id_ptec'); 
    ptec_element.innerHTML = data.ptec;
    ptec_element.classList.remove("bg-success", "bg-danger");
    ptec_element.classList.add(data.ptec ==  "HP" ? "bg-danger" : "bg-success") ;

    let maintemp_element = document.getElementById('id_main_temp'); 
    maintemp_element.innerHTML = data.maintemp + " °C";

    let mainpressure_element = document.getElementById('id_main_pressure'); 
    mainpressure_element.innerHTML = data.mainpressure+ " hPa";

    let time_element = document.getElementById('id_time'); 
    time_element.innerHTML =  data.time.toLocaleString();

    time_element.classList.remove("animation_on_id_time");

    window.setTimeout(function () {
      time_element.classList.add("animation_on_id_time");
    }, 350);

    var chart_elec = Chartkick.charts["chart_elec"];
    var chart_weather = Chartkick.charts["chart_weather"];
    chart_elec.updateData(data.chart_elec) ; chart_elec.redraw();
    chart_weather.updateData(data.chart_weather) ; chart_weather.redraw();
  }
});
